<template>
	<div class="w100 h-40"></div>
	<div class="oletter allbox pb-20">
		<div class="w100 mb-36">
      <div class="labelbox dp-f h-22 ai-c mt-46 mb-12">
        <span>*</span>
        <div class="mr-22">工商营业执照</div>
        <img src="@/assets/img/realname/example.png" class="w-16 h-16 mr-3 mt-2">
        <div class="download cu-p" @click="viewExample(['https://zhangyiqian.yilanshuzi.com/group1/M00/00/CC/wKgAXmQitfSAOu2FAA4HPInxe6Y191.png'])">查看示例</div>
      </div>
      <div class="mb-25 introduce">原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg .bmp .png格式照片，大小不超过1M</div>
      <dragbox :file-size="1" ref="dragboxRef4"></dragbox>
    </div>
	<div v-if="configu.mode == 1">
		<div class="dp-f mb-16">
			认证授权公函
			<!-- <statusIcon class="ml-7" title="待提交" CSStype="0" v-if="state.authenticationState == 5"/> -->
		</div>
		<div class="mb-27">
			<div class="introduce mb-18">下载并打印授权公函后，加盖公章扫描或拍照上传电子版，须确保授权公函上的信息清晰可见,大小不超过1M</div>
			<div class="download dp-f ai-c">
				<div class="dp-f ai-c cu-p" @click="download">
					<img src="@/assets/img/realname/file.png" class="w-16 h-16 mr-3">
					下载《认证授权公函》模版
				</div>
				<span class="bg-999 h-14 w-1 mr-14 ml-14"></span>
				<div class="dp-f ai-c cu-p" @click="viewExample(['https://zhangyiqian.yilanshuzi.com/group1/M00/00/CC/wKgAXmQitd2AKtLfAAGa16corMs067.png'])">
					<img src="@/assets/img/realname/example.png" class="w-16 h-16 mr-3">
					查看示例
				</div>
			</div>
		</div>
		<div class="w-605 mb-20">
			<!-- <img v-if="state.companyInfo.officialFetterFileUrl" style="max-width: 100%;max-height: 300px;"
				:src="state.companyInfo.officialFetterFileUrl"> -->
			<dragbox ref="dragboxRef"/>
		</div>
	</div>
	<div class="mb-16">
		身份证正反面
	</div>
	<div class="dp-f ai-c mb-20">
		<div class="introduce mr-20">请上传经办人身份证正反面，大小不超过1MB</div>
		<div class="download dp-f ai-c cu-p" @click="viewExample(['https://zhangyiqian.yilanshuzi.com/group1/M00/00/CC/wKgAXmQitBmAf0ZVAA2Jfp2vU0E325.png'])">
			<img src="@/assets/img/realname/example.png" class="w-16 h-16 mr-3">
			查看示例
		</div>
	</div>
	<div class="dp-f mb-20">
		<div class="w-234 h-146 mr-12">
			<dragbox :file-size="1" ref="dragboxRef2" :cssType="2"/>
		</div>
		<div class="w-234 h-146">
			<dragbox :file-size="1" ref="dragboxRef3" :cssType="3"/>
		</div>
	</div>
	<oabutton  v-model:loading="state.loading1" class="mb-50" width='106' height='48'
		title="提交" CSStype=2 style="border-radius: 4px;font-size: 16px;"  @buttonclick="submit1()"></oabutton>
			<!-- <oabutton v-if="state.authenticationState == 5||state.authenticationState == 3" v-model:loading="state.loading1" class="mb-50" width='106' height='48'
		title="提交" CSStype=2 style="border-radius: 4px;font-size: 16px;" @buttonclick="submit1()"></oabutton> -->
  </div>
  <authentication ref="authenticationRef" @Emit="state.IsrealName=true" :smstype="5"></authentication>
	<!--图片弹窗-->
  <el-image-viewer :url-list="dialogImageUrl" v-if="dialogVisibleImage" @close="dialogVisibleImage = false" />
</template>
<script setup>
import { reactive, ref, unref, getCurrentInstance,nextTick,defineEmits } from "vue";
import authentication from "@/views/user/components/setting/authentication.vue"//身份验证弹框
import { handleMessage } from "@/utils/server/confirm.js"
import statusIcon from "@/components/layout/parts/statusIcon.vue"
import dragbox from './dragbox.vue'//拖拽上传
import { uploadFile } from "@/utils/server/upload.js"
import { selectById } from "@/utils/server/getdata.js"
import { useStore } from "vuex";
import { httpToken } from "@/utils/request";
import { configu } from '@/utils/config'//引入公用文件
import qs from "qs";
const instance = getCurrentInstance();//强制刷新
const emit = defineEmits(['setStep'])
const dragboxRef = ref()//认证授权公函
const dragboxRef2 = ref()//身份证正面
const dragboxRef3 = ref()//身份证反面
const dragboxRef4 = ref()//工商营业执照
const authenticationRef = ref()
const dialogImageUrl = ref([])//预览图片路径
const dialogVisibleImage = ref(false)//缩略图预览弹框
const store = useStore();
const state = reactive({
	IsrealName:false,//是否进行过实名
	authenticationState: 1,//认证状态
	companyInfo: '',
	loading1: false,//loading 显示
	status: 0,//当前操作状态
});
// 查看示例
const viewExample=(urls)=>{
  dialogImageUrl.value=urls
  dialogVisibleImage.value = true
}
const download=()=>{
	window.open("https://zhangyiqian.yilanshuzi.com/group1/M00/00/CC/wKgAXmQitpeARCTSAABAAFLBceA910.doc?attname=《认证授权公函》模版.doc")
}
// 认证授权提交
const submit1 = () => {
	if (!unref(dragboxRef4).getFile()) {
		handleMessage('请先上传工商营业执照')
		return
	}
	if (configu.mode == 1){
		if (!unref(dragboxRef).getFile()) {
			handleMessage('请先上传认证授权公函')
			return
		}
	}
 	if (!unref(dragboxRef2).getFile()) {
		handleMessage('请先上传身份证正面')
		return
	}
	if (!unref(dragboxRef3).getFile()) {
		handleMessage('请先上传身份证反面')
		return
	}
	if(!state.IsrealName){
		handleMessage('请先完成身份认证')
		unref(authenticationRef).check()
		return
	}
	let officialFetterFileId//认证公函文件id
	let frontIDFileId//身份证正面文件id
	let reverseIDFileId//身份证反面文件id
	let businessFileId//认证授权公函id
	let promiseall = []
	if (configu.mode == 1) {
		let p1=uploadFile(unref(dragboxRef).getFile().file, "certification_officialFetterFileId").then((res) => {
			officialFetterFileId=res.data.id
		})
		promiseall.push(p1)
	} else {
		officialFetterFileId = ''
	}
	let p2=uploadFile(unref(dragboxRef2).getFile().file, "certification_frontIDFileId").then((res) => {
		frontIDFileId=res.data.id
	})
	promiseall.push(p2)
	let p3=uploadFile(unref(dragboxRef3).getFile().file, "certification_reverseIDFileId").then((res) => {
		reverseIDFileId=res.data.id
	})
	promiseall.push(p3)
	let p4=uploadFile(unref(dragboxRef4).getFile().file, "certification_businessFileId").then((res) => {
		businessFileId=res.data.id
	})
	promiseall.push(p4)

	state.loading1 = true
	Promise.all(promiseall).then(() => {
    httpToken({
			method: "post",
			url: '/admin/certification/enterpriseFour',
			data: {
				officialFetterFileId: officialFetterFileId,
				frontIDFileId: frontIDFileId,
				reverseIDFileId: reverseIDFileId,
				businessFileId,
				companyId: state.companyInfo.companyId,
				mode: configu.mode
			}
		}).then((res) => {
			selectById(res.data,2).then(res2 => {
				state.loading1 = false
				emit('setStep',4)
			}).catch(() => {
				state.loading1 = false
			})
		}).catch(() => {
			state.loading1 = false
		})
  }).catch(() => {
    state.loading1 = false
  })
}
const resetData = ((res, type) => {
	state.authenticationState = res.authenticationState,//认证状态

	state.companyInfo = res
	if (type) {
		instance.proxy.$forceUpdate();//强制刷新
	}
})
resetData(store.state.realName.companyInfo)
// console.log(store.state.realName.companyInfo,'store.state.realName.companyInfo')
</script>
<style lang="scss" scoped >
.oletter {
	padding-top: 60px;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #333333;

	.introduce {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.download {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #1890FF;
	}
}
.allbox{
	width: 760px;
	margin: 0 auto;
	padding: 46px 40px;
	background: #FFFFFF;
	border-radius: 12px;
}
</style>